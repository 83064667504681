<template>
  <div :class="{'opacity-50 pointer-events-none': isLoadingInitData}">
    <vx-card :title="'#' + (data.no_media || '')" class="mb-3" collapse-action :is-refresh="isLoadingInitData">
      <div class="vx-row">
        <div class="vx-col flex-1">
          <table class="table-info">
            <tr>
              <td class="font-semibold">Judul</td>
              <td>: {{ data.judul }}</td>
            </tr>
            <tr>
              <td class="font-semibold">Kode Proyek</td>
              <td>: {{ data.kode_proyek }}</td>
            </tr>
            <tr>
              <td class="font-semibold">Nama Proyek</td>
              <td>: {{ data.nama_proyek }}</td>
            </tr>
            <tr>
              <td class="font-semibold">Files</td>
              <td>: <span v-if="data.files_url && data.files_url.length > 0" @click.stop="showGallery(data.files_url)" class="text-primary whitespace-no-wrap underline select-none cursor-pointer">Lihat {{ data.files_url.length }} File</span></td>
            </tr>
          </table>
        </div>
        <div class="vx-col flex-1">
          <table class="table-info">
            <tr>
              <td class="font-semibold">Nama Platform</td>
              <td>: {{ data.nama_platform }}</td>
            </tr>
            <tr>
              <td class="font-semibold">Status</td>
              <td>: {{ data.status }}</td>
            </tr>
            <tr>
              <td class="font-semibold">Tipe Platform</td>
              <td>: {{ data.tipe_platform }}</td>
            </tr>
          </table>
        </div>
      </div>
    </vx-card>
    <vx-card>
      <p class="font-semibold mb-5">Deskripsi: </p>
      <div class="overflow-auto">
        <div v-html="data.deskripsi"></div>
      </div>
    </vx-card>
  </div>
</template>

<script>
import MediaRepository from '@/repositories/marketing/media-repository'
import GLightbox from 'glightbox'
import 'glightbox/dist/css/glightbox.min.css'
import _ from 'lodash'

export default {
  name: 'MediaDetail',
  mounted () {
    this.getInitData()
  },
  data () {
    return {
      isLoadingInitData: false,
      data: {}
    }
  },
  methods: {
    getInitData () {
      this.isLoadingInitData = true

      const idMedia = this.$route.params.idMedia
      MediaRepository.show(idMedia)
        .then(response => {
          this.data = response.data.data
        })
        .catch(error => {
          console.log(error)
          this.notifyError('Terjadi kesalahan.')
        })
        .finally(() => {
          this.isLoadingInitData = false
        })
    },
    showGallery (listMedia) {
      const lightbox = GLightbox()
      const elements = _.map(listMedia, item => ({ href: item }))
      lightbox.setElements(elements)
      lightbox.open()
    }
  }
}
</script>
